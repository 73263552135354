import React from "react"

import styles from './homeData.module.css'
import * as numberUtil from '../../../utils/numberUtil'

const DataPoint = ({value, title, icon, iconAlt}) => {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "20px"}}>
      <div className="d-flex justify-content-center align-items-center">
          <img src={icon} alt={iconAlt} style={{height: "35px", width: "35px"}}/>
          <h1 style={{marginLeft: "20px"}}>{value}</h1>
      </div>
      <div className={styles.dataTitle}>{title}</div>
    </div>
  )
}

const HomeData = ({ homeData }) => {
    const quote = "\"The power of community to create health is far greater than any physician, clinic or hospital.\""
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{background: "#006AC7", color: "white", padding: "20px"}}>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className={styles.quote}>{quote}</div>
            <div style={{height: "10px"}}/>
            <div className={styles.person}>Dr. Mark Hyman</div>
            <div style={{height: "5px"}}/>
            <div className={styles.personDescription}>Cleveland Clinic Center for Functional Medicine</div>
          </div>
          <div style={{height: "20px"}}/>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-3">
                <DataPoint
                  value={`${numberUtil.formatNumber(Math.round(homeData["community"]/100)*100)}+`}
                  title={"Community"}
                  icon={"/images/icons/library_item_people.svg"}
                  iconAlt={"community"}
                />
              </div>
              <div className="col-12 col-md-3">
                <DataPoint
                  value={`${numberUtil.formatNumber(Math.round(homeData["health_data_points"]/10000)*10000)}+`}
                  title={"Health Data Points"}
                  icon={"/images/icons/diagnosis.svg"}
                  iconAlt={"community"}
                />
              </div>
              <div className="col-12 col-md-3">
                <DataPoint
                  value={numberUtil.formatNumber(homeData["conditions"])}
                  title={"Conditions"}
                  icon={"/images/icons/symptom.svg"}
                  iconAlt={"community"}
                />
              </div>
              <div className="col-12 col-md-3">
                <DataPoint
                  value={numberUtil.formatNumber(homeData["treatments"])}
                  title={"Treatments"}
                  icon={"/images/icons/treatment.svg"}
                  iconAlt={"community"}
                />
              </div>
            </div>
          </div>
        </div>
    )
}
export default HomeData
