import React from "react"

import styles from './book.module.css'

const Book = ({ book }) => (
  <a href={book["link"]} style={{color: "#495057"}}>
    <div className={styles.book}>
        <img src={`/images/icons/book.svg`} alt={"book"} className={styles.img}/>
        <div className={styles.text}>
            <h3 className={styles.title}>{book["title"]}</h3>
            <p className={styles.description}>{book["description"]}</p>
        </div>
    </div>
  </a>
)
export default Book