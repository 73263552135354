import React from 'react'
import { Link } from 'gatsby'
import 'scss/gatstrap.scss'

import FooterImage from '../../images/footer'
import styles from "./footer.module.css"

const Footer = () => {
    const twitterUrl = 'https://twitter.com/joinwana'
    const facebookUrl = 'https://www.facebook.com/Wana-1198293280341939/'
    const instagramUrl = 'https://www.instagram.com/joinwana/'

    return (
        <>
            <footer>
                <FooterImage/>
                <div className="container-fluid">
                    <div className={styles.divider}/>
                    <div className={styles.socialIcons}>
                        <a href={ instagramUrl } className={styles.social} target="_blank" rel="noopener noreferrer"><img src="/images/icons/instagram.svg" alt="Instagram" /></a>
                        <a href={ twitterUrl } className={styles.social} target="_blank" rel="noopener noreferrer"><img src="/images/icons/twitter.svg" alt="Twitter" /></a>
                        <a href={ facebookUrl } className={styles.social} target="_blank" rel="noopener noreferrer"><img src="/images/icons/facebook.svg" alt="Facebook" /></a>
                    </div>
                    <div>
                        <div className={styles.links}>
                            <Link to="/about/">
                                About
                            </Link>
                            <a href="mailto:hello@joinwana.com" target="_blank">Contact</a>
                            <a href={'/community-guidelines.pdf'} target="_blank">Community Guidelines</a>
                            <a href={'/terms-of-use.pdf'} target="_blank">Terms of Service</a>
                            <a href={'/privacy-policy.pdf'} target="_blank">Privacy Policy</a>
                        </div>
                        <div>
                            © 2020 Wana
                        </div>
                    </div>
                    <div className={styles.divider}/>
                    <p className={styles.disclaimer}>Wana does not provide medical advice, and the content provided on www.joinwana.com and the Wana app is for informational and educational purposes only. It is not intended to take the place of medical advice or treatment from a physician, health provider, or health practitioner. You should always consult with a medical professional about health questions and before beginning treatment of any kind. Wana encourages you to share your experiences with other Wana community members, but it does not endorse any particular provider or treatment, and all opinions expressed by community members are their own. If you are experiencing a medical emergency, please seek medical help immediately.</p>
                </div>
            </footer>
        </>
    )
}

Footer.propTypes = {}

export default Footer
