import React from "react"
import styles from './blogHeader.module.css'

const BlogHeader = ({ version }) => {
    return (
    <div className={styles.body}>
      <img src={"/images/icons/wanaknow.svg"} alt={"wanaknow"} className={styles.header}/>
    </div>
    )
}
export default BlogHeader
