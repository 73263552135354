import React from "react";
import Slider from "react-slick"

import styles from './carousel.module.css'

class Carousel extends React.Component {
    render() {
        let items = [
            {
                title: "Joining An Online Support Group For My Chronic Condition Totally Changed My Outlook",
                img: "/images/news/womens_health.png",
                displayLink: "womenshealthmag.com",
                link: "https://www.womenshealthmag.com/health/a30613247/i-joined-a-crohns-support-group/"
            },
            {
                title: "Social Impact Heroes: How Nicole Krinick and Evan Golub of Wana are helping people with chronic and invisible conditions to find what will help them get well",
                img: "/images/news/thrive_global.png",
                displayLink: "thriveglobal.com",
                link: "https://thriveglobal.com/stories/social-impact-heroes-how-nicole-krinick-and-evan-golub-of-wana-are-helping-people-with-chronic-and-invisible-conditions-to-find-what-will-help-them-get-well/"
            },
            {
                title: "Wana App Is A Community For People With Chronic Illness",
                img: "/images/news/bustle.png",
                displayLink: "bustle.com",
                link: "https://www.bustle.com/p/wana-app-is-a-community-for-people-with-chronic-illness-19349979"
            },
            {
                title: "Justin Bieber's Lyme Disease: An Expert Shares Potential Good News, Possible Complications",
                img: "/images/news/billboard.png",
                displayLink: "billboard.com",
                link: "https://www.billboard.com/articles/columns/pop/8547656/justin-bieber-lyme-disease-diagnosis-expert-breakdown"
            },
        ]
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                }
              }
            ]
        };
        return (
          <div className={styles.body}>
            <Slider {...settings}>
            {items.map((item, i) => (
                <a key={i} href={item["link"]} target="_blank" rel="noopener noreferrer">
                    <div className={styles.card}>
                    <div className={styles.img}>
                        <img src={item["img"]} className={styles.image}/>
                    </div>
                    <h3 className={styles.title}>
                        {item["title"]}
                    </h3>
                    <p className={styles.link}>{item["displayLink"]}</p>
                </div>
                </a>
            ))}
            </Slider>
          </div>
        );
    }
}

export default Carousel
