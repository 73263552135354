import React from "react"

import PhoneNumberInput from '../PhoneNumberInput/phoneNumberInput'

const JoinCommunity = ({ version }) => {

    const color = version === 1 ? "#FFDFE7" : "#FADF94"
    const image = version === 1 ? "/images/icons/community_pink.svg" : "/images/icons/community_yellow.svg"

    return (
        <div className="container">
        <div style={{margin: "40px 0px", padding: "30px 80px", background: color, borderRadius: "10px"}}>
            <div className="row">
                <div className="col-xs-12 col-md-8">
                    <h2 style={{fontSize: "28px"}}>
                        Join the Wana community
                    </h2>
                    <p>Make new friends, find support, and learn from others. Enter your phone number and we'll text you the app!</p>
                    <PhoneNumberInput/>
                </div>
                <div className="col-xs-12 col-md-4 d-flex align-items-center justify-content-center">
                  <img src={image} alt="JoinWana"/>
                </div>
            </div>
        </div>
    </div>
    )
}
export default JoinCommunity
