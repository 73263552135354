import React from "react"

import styles from './podcast.module.css'

const Podcast = ({ podcast }) => (
    <a href={podcast["link"]} style={{color: "#495057"}}>
        <div className={styles.podcast}>
            <img src={`/images/icons/podcast.svg`} alt={"podcast"} className={styles.img}/>
            <div className={styles.text}>
                <h3 className={styles.title}>{podcast["title"]}</h3>
                <p className={styles.description}>{podcast["description"]}</p>
            </div>
        </div>
    </a>
  
)
export default Podcast