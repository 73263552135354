import React from "react"
import fetch from 'node-fetch'
import {
  BrowserView,
  MobileView,
} from "react-device-detect"

import styles from './phoneNumberInput.module.css'

class PhoneNumberInput extends React.Component {

    state = {
        phone: undefined,
    }

    sendPhoneNumber = () => {
        const rawResponse = fetch("https://www.joinwana.com/api/v1/sms/trigger-sms-alert", {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify({phone: this.state.phone})
        });
        this.setState({phone: undefined})
    }

    downloadApp = () => {
        window.open("https://joinwana.onelink.me/JQec/a0028272")
    }

    render() {
        return (
            <>
            <BrowserView>
              <div className="d-flex flex-column align-items-center justify-content-ceter">
                <p>Send a download link to your phone.</p>
                <div className="d-flex align-items-center justify-content-center">
                    <input
                        type="text"
                        className={styles.input}
                        placeholder="Your phone number"
                        aria-label="Phone Number"
                        value={this.state.phone}
                        onChange={(e) => this.setState({phone: e.target.value})}
                    />
                    <div style={{width: "10px"}}/>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={this.sendPhoneNumber}
                    >Send</button>
                </div>
              </div>
            </BrowserView>
            <MobileView>
              <button
                  type="button"
                  className="btn btn-secondary"
                  style={{marginTop: "20px"}}
                  onClick={this.downloadApp}
              >Download App</button>
            </MobileView>

            </>
        );
    }
}

export default PhoneNumberInput
