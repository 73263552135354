import React, {Fragment} from 'react'
import { navigate } from 'gatsby'

import { Typeahead, TypeaheadInputSingle } from 'react-bootstrap-typeahead'

import 'scss/gatstrap.scss'

import styles from "./searchbar.module.css"

class SearchBar extends React.Component {
    state = {
      selectedOption: 'renderInput',
    };

    render() {
      const { librarySearch, isNav } = this.props;
      const options = librarySearch.map(item => ({id: item["slug"].toLowerCase(), label: item["name"]}))

      return (
        <div className={styles.body}>
          <div className={isNav ? "d-none d-md-flex" : ""}>
              <Typeahead
                  id="nav-search"
                  className={isNav ? styles.typeahead : styles.typeahead2}
                  onChange={(selected) => {
                      const firstResult = selected[0]
                      if (!!firstResult) {
                          navigate(firstResult.id)
                      }
                  }}
                  placeholder={"Search Treatments, Diagnoses, Symptoms..."}
                  options={options}
                  renderInput={this._renderInput}
              />
          </div>
        </div>
      );
    }

    _renderInput = (inputProps) => {
      return <TypeaheadInputSingle {...inputProps}/>
    }
  }

export default SearchBar
