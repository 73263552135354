import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'

import SearchBar from '../SearchBar/searchbar'

import 'scss/gatstrap.scss'

const Header = ({ data, pageContext, showSearch }) => {
    const site = data.allGhostSettings.edges[0].node
    return (
        <>
            <header>
                <nav className="navbar fixed-top navbar-expand navbar-dark flex-column flex-md-row bg-primary" style={{padding: "20px"}}>
                  <ul className="navbar-nav bd-navbar-nav d-flex align-items-center justify-content-between" style={{width: "100%"}}>
                      <Link to="/">
                          <img style={{height: "50px"}} src={site.logo} alt={site.title} />
                      </Link>
                      { showSearch !== false &&
                          <SearchBar librarySearch={pageContext.librarySearch} isNav={true}/>
                      }
                      <div className={"d-flex align-items-center justify-content-center"}>
                        <Link to="/library/" className="nav-link active" style={{marginLeft: "auto"}}>
                            WanaLibrary
                        </Link>
                        <Link to="/blog/" className="nav-link active" style={{marginLeft: "auto"}}>
                            Blog
                        </Link>
                        <Link to="/about/" className="nav-link active" style={{marginRight: "20px"}}>
                            About
                        </Link>
                      </div>
                  </ul>
                </nav>
            </header>
        </>
    )
}

Header.propTypes = {
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired
}

const HeaderSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query HeaderSettingsQuery {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <Header data={data} {...props} />}
    />
)

export default HeaderSettingsQuery
