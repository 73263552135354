import React from "react"

import styles from './post.module.css'

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const Post = ({ post }) => (
  <a href="https://wana.page.link/website" style={{color: "#495057"}}>
    <div className={styles.post}>
        <img src={post["face"]} alt={"face"} className={styles.img}/>
        <div className={styles.text}>
            <p className={styles.description}>{post["text_original"]}</p>
            <div className="d-flex align-items-center justify-content-end">
              <img src="/images/icons/heart_filled.svg" alt="Heart" className={styles.heart}/>
              <div className={styles.likesCount}>
                {getRandomInt(7, 21)}
              </div>
              <img src="/images/icons/comment.svg" alt="Comment" className={styles.comment}/>
              <div className={styles.commentsCount}>
                {getRandomInt(4, 17)}
              </div>
            </div>
        </div>
    </div>
  </a>
)
export default Post
