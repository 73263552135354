import React from "react"

import styles from './downloadLinks.module.css'

const DownloadLinks = ({ title }) => {
    const displayTitle = title || "Download the app today"
    return (
        <div className="d-flex flex-column justify-content-center align-items-center" style={{background: "#f8f8f8", padding: "20px"}}>
          <div className="d-flex justify-content-center align-items-center">
            <img src={"/images/icons/app_icon.svg"} alt={"app_icon"} className={styles.img}/>
            <a href={"https://joinwana.onelink.me/JQec/a0028272"} target="_blank"><h2>{displayTitle}</h2></a>
          </div>
        </div>
    )
}
export default DownloadLinks
